'use client';
import { useEffect, useRef, useState } from 'react';
import styles from './CookieConsent.module.scss';
import Button from '@/components/ui/Button';
import Switch from '@/components/ui/Switch';
import { getCookie } from '@/utils/get-cookie';
import { setCookie } from '@/utils/set-cookie';
import type { ContentProps } from './CookieConsent.types';

type Consent = {
  necessary: boolean;
  marketing: boolean;
  analytics: boolean;
  preferences: boolean;
};

function setConsent(consent: Consent) {
  const consentMode = {
    functionality_storage: consent.necessary ? "granted" : "denied",
    security_storage: consent.necessary ? "granted" : "denied",
    ad_storage: consent.marketing ? "granted" : "denied",
    ad_user_data: consent.marketing ? "granted" : "denied",
    ad_personalization: consent.marketing ? "granted" : "denied",
    analytics_storage: consent.analytics ? "granted" : "denied",
    personalization_storage: consent.preferences ? "granted" : "denied",
  } as const;
  gtag("consent", "update", consentMode);
  setCookie("cookie-consent", JSON.stringify(consentMode), 365);
}

export default function Content({ CloseIcon }: ContentProps) {
  const wrapper = useRef<HTMLDivElement>(null);
  const [showSettings, setShowSettings] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (getCookie("cookie-consent") === null) {
      gtag("consent", "default", {
        functionality_storage: "denied",
        security_storage: "denied",
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
        personalization_storage: "denied",
      });
      setShowBanner(true);
    } else {
      gtag("consent", "default", JSON.parse(getCookie("cookie-consent")!));
    }
  }, []);

  const acceptAll = () => {
    setConsent({
      necessary: true,
      marketing: true,
      analytics: true,
      preferences: true,
    });
    setShowBanner(false);
  };

  const rejectAll = () => {
    setConsent({
      necessary: false,
      marketing: false,
      analytics: false,
      preferences: false,
    });
    setShowBanner(false);
  };

  const acceptPart = () => {
    setShowBanner(false);
    setConsent({
      necessary: true,
      preferences: wrapper.current?.querySelector<HTMLInputElement>('input[id="preferences"]')?.checked || false,
      analytics: wrapper.current?.querySelector<HTMLInputElement>('input[id="analytics"]')?.checked || false,
      marketing: wrapper.current?.querySelector<HTMLInputElement>('input[id="marketing"]')?.checked || false,
    });
  };

  return (
    <div
      className={styles['Content']}
      aria-hidden={!showBanner}
      ref={wrapper}
    >
      <button
        className={styles.RejectAll}
        onClick={rejectAll}
      >
        <span>Ich möchte keine Cookies</span>
        {CloseIcon}
      </button>
      <header>
        <h2>Einen Cookie zum Kaffee?</h2>
        <div className={styles.paragraph}>Cookies machen unsere Website für dich benutzerfreundlicher und sorgen dafür, dass sie zuverlässig funktioniert. Cookies ermöglichen es uns auch, Inhalte und Werbung auf deine Interessen abzustimmen.</div>
      </header>
      <div
        className={styles.settings}
        style={{ display: showSettings ? undefined : 'none' }}
        data-visible={showSettings}
      >
        <div className={styles.header}>
          <h3>Cookie-Einstellungen</h3>
          <div className={styles.paragraph}>Im Folgenden erfährst du, welche Daten wir in Cookies sammeln und warum wir sie sammeln. Du musst nicht mit allen Cookies einverstanden sein. Du kannst deine Einstellungen jederzeit auf der Seite Cookies ändern.</div>
        </div>
        <div className={styles.group}>
          <Switch
            inputProps={{
              checked: true,
              disabled: true,
            }}
          >
            Wesentlich
          </Switch>
          <p className={styles.description}>Unverzichtbare Cookies tragen dazu bei, unsere Website nützlich zu machen, indem sie grundlegende Funktionen wie die Navigation auf der Website und den Zugang zu sicheren Bereichen der Website ermöglichen. Ohne diese Cookies werden einige grundlegende Funktionen der Website nicht richtig funktionieren.</p>
        </div>
        <div className={styles.group}>
          <Switch
            inputProps={{
              id: 'preferences'
            }}
          >
            Präferenz-Cookies
          </Switch>
          <p className={styles.description}>Präferenz-Cookies ermöglichen es der Website, sich an Informationen zu erinnern, die das Verhalten oder Aussehen der Website verändern, z. B. Ihre bevorzugte Sprache oder die Region, in der Sie sich befinden.</p>
        </div>
        <div className={styles.group}>
          <Switch
            inputProps={{
              id: 'analytics'
            }}
          >
            Analytische Cookies
          </Switch>
          <p className={styles.description}>Analytische Cookies helfen uns zu verstehen, wie Nutzer mit unserer Website interagieren, indem sie Informationen anonym sammeln und melden. Diese Daten werden verwendet, um die Struktur und den Inhalt der Website zu verbessern.</p>
        </div>
        <div className={styles.group}>
          <Switch
            inputProps={{
              id: 'marketing'
            }}
          >
            Marketing
          </Switch>
          <p className={styles.description}>Marketing-Cookies werden verwendet, um Besucher auf Websites zu verfolgen. Ihr Zweck ist es, Anzeigen zu schalten, die für einzelne Nutzer relevant und interessant sind und somit für Verlage und Drittanbieter wertvoller sind.</p>
        </div>
      </div>
      <div className={styles.controls}>
        {showSettings ? (
          <button
            className={styles.button}
            onClick={acceptPart}
          >
            Ausgewählte speichern
          </button>
        ) : (
          <button
            className={styles.button}
            onClick={() => setShowSettings(true)}
          >
            Einstellungen
          </button>
        )}
        <Button onClick={acceptAll}>Alle akzeptieren</Button>
      </div>
    </div>
  );
}
