import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/font/google/target.css?{\"path\":\"src/global/fonts.ts\",\"import\":\"Rosario\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"fallback\":[\"sans-serif\"],\"display\":\"swap\"}],\"variableName\":\"Rosario\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/font/google/target.css?{\"path\":\"src/global/fonts.ts\",\"import\":\"Libre_Baskerville\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"fallback\":[\"serif\"],\"display\":\"swap\",\"variable\":\"--font-libre-baskerville\"}],\"variableName\":\"LibreBaskerville\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/global/CookieConsent/_Content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/global/Footer/_CookieButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/global/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/global/CookieConsent/CookieConsent.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/global/Footer/Newsletter/_Form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/global/Header/_Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/global/global.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/global/Header/Header.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/global/SocialMedia/SocialMedia.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/global/Footer/Newsletter/Newsletter.module.scss");
